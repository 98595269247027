import { Capacitor } from '@capacitor/core'

export const getProvider = () => {
  switch (Capacitor.getPlatform()) {
    case 'ios':
      return 'apple' as const
    // case 'android':
    //   return 'google'
    default:
      return 'stripe' as const
  }
}
